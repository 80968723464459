/// <reference types="@types/googlemaps" />
import axios from 'axios'
import _ from 'lodash'

class Map {
  public static async getAddressFromPostalCode(postalCode: string) {
    try {
      const response = await axios.get<{
        results: {
          ADDRESS: string
          LATITUDE: string
          LONGITUDE: string
        }[]
      }>(
        `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y`,
      )
      if (!_.isEmpty(response.data.results)) {
        return {
          address: response.data.results[0].ADDRESS,
          latitude: response.data.results[0].LATITUDE,
          longitude: response.data.results[0].LONGITUDE,
        }
      }

      throw new Error('One map fail to get address')
    } catch (error) {
      try {
        const r = await this.getAddressFromPostalCodeUsingGoogleMap(postalCode)
        return r
      } catch (error) {
        return {
          address: postalCode,
          latitude: null,
          longitude: null,
        }
      }
    }
  }

  public static getAddressFromPostalCodeUsingGoogleMap(
    postalCode: string,
  ): Promise<{ address: string; latitude: string; longitude: string }> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode(
        {
          address: postalCode,
          componentRestrictions: {
            country: 'SG',
            postalCode,
          },
        },
        results => {
          if (!_.isEmpty(results)) {
            resolve({
              address: _.toUpper(results[0].formatted_address),
              latitude: results[0].geometry.location.lat.toString(),
              longitude: results[0].geometry.location.lng.toString(),
            })
          } else {
            reject(new Error('Fail to get location'))
          }
        },
      )
    })
  }
}

export { Map as MapService }
